import React, { Fragment } from "react"

import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BannerTop = ({ title, subtitle }) => {
  return (
    <Fragment>
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "banner-1.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          // Set ImageData.
          const imageData = data.file.childImageSharp.fluid
          return (
            <BackgroundImage
              Tag="section"
              className="page-head"
              fluid={imageData}
              backgroundColor={`#040e18`}
            >
              <div className="container">
                <div className="wrap clearfix">
                  <h1 className="page-title">
                    <span>{title}</span>
                  </h1>

                  {subtitle && <p>{subtitle}</p>}
                </div>
              </div>
            </BackgroundImage>
          )
        }}
      />
    </Fragment>
  )
}

export default BannerTop
