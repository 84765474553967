import React, { Fragment, useEffect, useState } from "react"
import axios from "axios"

import sanitizeHtml from "sanitize-html"
import { Link } from "gatsby"
import FormatCurrency from "../../utils/format-currency"

const FeaturedProperties = ({ quantity }) => {
  const [featuredProperties, setFeaturedProperties] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(true)
    const searchProperties = async () => {
      await axios
        .get(
          `https://api.clasihome.com/rest/properties?typeId=office&id=5fbd47668abd7800086f0d10&status=PUBLICADA`
        )
        .then(res => {
          console.log(res.data.properties)
          setFeaturedProperties(res.data.properties)
          setloading(false)
        })
        .catch(setloading(false))
    }
    searchProperties()
  }, [quantity])

  const sanatizeDescrition = descripcion => {
    if (descripcion.length > 125) {
      return sanitizeHtml(`${descripcion.substring(0, 125)}...`, {
        allowedTags: [],
        allowedAttributes: {
          a: ["href", "target"],
        },
      })
    } else {
      return sanitizeHtml(descripcion)
    }
  }

  return (
    <Fragment>
      {!loading && featuredProperties.length > 0 && (
        <section class="widget">
          <h3 class="title">Últimas Propiedades</h3>
          <ul class="featured-properties">
            {featuredProperties.map(fp => (
              <li>
                <figure>
                  <Link to={`detalle-propiedad?id=${fp._id}`}>
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        height: "200px",
                        width: "100%",
                        verticalAlign: "middle",
                        border: "0",
                      }}
                      src={fp.mainImage}
                      alt="property 10"
                    />
                  </Link>
                </figure>
                <h4>
                  <Link to={`detalle-propiedad?id=${fp._id}`}>{fp.titulo}</Link>
                </h4>
                <p>
                  {sanatizeDescrition(fp.publicObservations)}
                  <Link to={`detalle-propiedad?id=${fp._id}`}>Ver más</Link>
                </p>
                <span class="price">
                  {" "}
                  {fp.currency === "CLP" ? "$" : fp.currency}{" "}
                  {FormatCurrency(fp.currency, fp.value)}
                </span>
              </li>
            ))}
          </ul>
        </section>
      )}
    </Fragment>
  )
}

export default FeaturedProperties
