import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"

import Img3 from "../bg/3.jpg"
import Img4 from "../bg/4.jpg"
import Img5 from "../bg/5.jpg"
import Img6 from "../bg/6.jpg"
import Img7 from "../bg/7.jpg"
import Img8 from "../bg/8.jpg"
import Img9 from "../bg/9.jpg"

import { Helmet } from "react-helmet"
import Slider from "react-slick"

const QuinesSomosPage = () => {
  const [pathname, setPathname] = useState("")
  const settingsSlick = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    adaptiveHeight: true,
    variableWidth: false,
    slidesToScroll: 1,
    useCss: true,
    fade: true,
    className: "slider-quienes-somos",
  }
  useEffect(() => {
    setPathname(window.location.href)
  }, [])
  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <SEO title={`Nuestra empresa `} pathname={pathname} />
      <BannerTop title="Quiénes somos" />
      <div class="container contents single">
        <div class="row">
          <div class="span9 main-wrap">
            <div class="main">
              <div class="inner-wrapper" style={{ padding: "10px 20px " }}>
                <article class="page type-page clearfix">
                  <Slider {...settingsSlick}>
                    <div>
                      <img src={Img3} />
                    </div>
                    <div>
                      <img src={Img4} />
                    </div>
                    <div>
                      <img src={Img5} />
                    </div>
                    <div>
                      <img src={Img6} />
                    </div>
                    <div>
                      <img src={Img7} />
                    </div>
                    <div>
                      <img src={Img8} />
                    </div>
                    <div>
                      <img src={Img9} />
                    </div>
                  </Slider>
                  <hr></hr>
                  <h3>Historia</h3>
                  <p style={{ textAlign: "justify" }}>
                  <strong>AgenteInmobiliario.cl</strong>&nbsp;es una marca de la empresa &ldquo;Desarrollo &amp; Inversiones Inmobiliarias JC SpA.&rdquo;, fundada en el mes de Abril de&nbsp;2006, por Don Jos&eacute; Miguel C&aacute;rdenas, un Ingeniero Civil Industrial con diplomados en: Evaluaci&oacute;n de Proyectos, Gesti&oacute;n Empresarial y Formaci&oacute;n Gerencial Avanzada. Con m&aacute;s de 28 a&ntilde;os de experiencia en diversos cargos gerenciales en destacadas empresas,&nbsp;decidi&oacute;&nbsp;crear, desarrollar y ofrecer un servicio de corretaje y desarrollo inmobiliario altamente profesional, incorporando&nbsp;tecnolog&iacute;a de punta, servicio y nuevas tendencias estrat&eacute;gicas de negocios, bajo el concepto de&nbsp;<em>&ldquo;Desarrollo Inmobiliario&rdquo;</em>,&nbsp;la evoluci&oacute;n del&nbsp;antiguo corredor de propiedades al nuevo ejecutivo profesional, &ldquo;<strong>Agente Inmobiliario</strong>&rdquo;.</p>
<p>
                  </p>
                  <hr />
                  <h3>
                    <strong>Gestión</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  <strong>AgenteInmobiliario.cl</strong>&nbsp;ha ayudado a muchas familias, personas, empresas e inversionistas, en&nbsp;la b&uacute;squeda de propiedades y oportunidades de negocios inmobiliarios, destacando la gesti&oacute;n, incorporaci&oacute;n de innovaciones tecnol&oacute;gicas, comunicaci&oacute;n, calidad de servicio, &eacute;tica y excelencia profesional. Es as&iacute; como &ldquo;AgenteInmobiliario.cl&rdquo;, ofrece a sus clientes una amplia gama de alternativas, preocupado por los intereses del comprador y vendedor en cada una de las etapas de negociaci&oacute;n, otorgando la m&aacute;s alta asesor&iacute;a y servicio en&nbsp;el proceso de compra y&nbsp;venta, tasaci&oacute;n, arriendo, obtenci&oacute;n de financiamiento, estudio de t&iacute;tulos, administraci&oacute;n de propiedades, b&uacute;squeda y desarrollo de oportunidades&nbsp;Inmobiliarias.
                  </p>

                  <hr />
                  <h3>
                    <strong>Misión</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  Revolucionar el mercado Inmobiliario Chileno, a trav&eacute;s de productos, servicios innovadores y plataformas tecnol&oacute;gicas integrales, que solucionen de manera &aacute;gil y efectiva las necesidades de nuestros clientes.
                  </p>

                  <hr />
                  <h3>
                    <strong>Visión</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  Posicionarnos dentro de las primeras cinco empresas Chilenas del Real Estate y la Administraci&oacute;n de Activos Fijos, apuntando a la consolidaci&oacute;n de un modelo de arriendo m&aacute;s justo.
                  </p>

                  <hr />
                  <h3>
                    <strong>Lo que nos diferencia</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                    17 años de experiencia.
                    <br />
                    Plataforma de administración tecnológica inteligente.
                    <br />
                    Soluciones ágiles.
                    <br />
                    Equipo humano altamente profesional.
                    <br />
                  </p>

                  <hr />
                  <h3>
                    <strong>Valores</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                    Orientación al cliente
                    <br />
                    Transparencia
                    <br />
                    Responsabilidad
                    <br />
                    Compromiso <br />
                    Diligencia
                   
                  </p>

                  <hr />
                  <h3 style={{ textAlign: "justify" }} align="justify">
                    <strong>Equipo Humano</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  <strong>AgenteInmobiliario.cl</strong>&nbsp;cuenta con un equipo profesional de planta de la m&aacute;s alta&nbsp;experiencia en el corretaje y servicio inmobiliario,&nbsp;nuestros colaboradores&nbsp;conocen cada rinc&oacute;n de las comunas y regiones donde estamos presentes,&nbsp;adem&aacute;s, nuestra&nbsp;red de corredores integrados y tasadores siguen sumando. AgenteInmobiliario.cl cuenta con un equipo de dise&ntilde;adores, programadores, publicistas, periodistas, abogados e ingenieros que&nbsp;desarrollan y actualizan diariamente sus portales de negocios.
                  </p>
                  <hr />
                  <h3 style={{ textAlign: "justify" }} align="justify">
                    <strong>Posicionamiento –&nbsp;&nbsp;</strong>
                    <strong style={{ fontSize: "16px" }}>
                      Portales Agente Inmobiliario – Arriendo Inmobiliario –
                      Círculo Inversionista&nbsp;
                    </strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  <strong>AgenteInmobiliario.cl</strong>&nbsp;posee diversas plataformas webs&nbsp;con&nbsp;tecnolog&iacute;a de punta, desarrolladas en Joomla y YouJoomla, WordPress, Bases de Datos y Sistemas de Gesti&oacute;n, contamos con sistemas de traductores de idiomas, potenciado por redes sociales como YouTube, Facebook, Twitter, LinkedIn e Instagram, adem&aacute;s de corredores integrados, franquiciados, personas, empresas, inversores, patrocinadores de servicios, inmobiliarias, bases de datos, boletines de oportunidades, links con&nbsp;portales estrat&eacute;gicos,&nbsp;canal de inversionistas, alianzas con bancos e instituciones financieras, empresas de tasaciones, red de compradores e inversionistas, vendedores e interesados&nbsp;en general,&nbsp;tanto en Chile como el extranjero.
                  </p>
                  <hr />
                  <h3 style={{ textAlign: "justify" }} align="justify">
                    <strong>Efectividad – Experiencia</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  Conf&iacute;e su propiedad, la b&uacute;squeda o su proyecto inmobiliario a &ldquo;AgenteInmobiliario.cl&rdquo;,&nbsp;y deje en manos de expertos encontrar y desarrollar lo que usted necesita.
                  </p>
                  <hr />
                  <h3 style={{ textAlign: "justify" }} align="justify">
                    <strong>Sinergia</strong>
                  </h3>
                  <p style={{ textAlign: "justify" }} align="justify">
                  Desarrollo &amp; Inversiones Inmobiliarias JC SpA., es due&ntilde;a de los portales www.agenteinmobiliario.cl &ndash;&nbsp;www.circuloinversionista.cl &ndash; www.arriendoinmobiliario.cl. &ndash; www.trustrent.cl
                  </p>
                  <hr />
                </article>
              </div>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={3} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default QuinesSomosPage
